/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, JSXElementConstructor, Key, ReactElement } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React TS exampless
import Sidenav from "components/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React TS themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from "assets/theme-dark";

import routes from "routes";
import adminRoutes from "admin.routes";

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import axiosConfig from "axios/axios";
import LogIn from "layouts/authentication/login";
import { loginModeAtom } from "atoms/globalAtoms";
import { useAtom } from "jotai";
import SignUp from "layouts/authentication/sign-up";
import LogInAdmin from "layouts/authentication/login-admin";
import { GoogleOAuthProvider } from "@react-oauth/google";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  axiosConfig();

  const [, setLoginMode] = useAtom(loginModeAtom);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setLoginMode("main");
    } else if (localStorage.getItem("admin-token")) {
      setLoginMode("admin");
    }
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="그린랩스 Farm Manager"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      {layout === "admin" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Farm Manager Admin"
            routes={adminRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Routes>
          {!localStorage.getItem("token") && (
            <>
              <Route path="/login" element={<LogIn />} />
              <Route path="/login/admin" element={<LogInAdmin />} />
              <Route path="/sign-up" element={<SignUp />} />
            </>
          )}
          {getRoutes(adminRoutes)}
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/farm-info" />} />
        </Routes>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

// const configsButton = (
//   <MDBox
//     display="flex"
//     justifyContent="center"
//     alignItems="center"
//     width="3.25rem"
//     height="3.25rem"
//     bgColor="white"
//     shadow="sm"
//     borderRadius="50%"
//     position="fixed"
//     right="2rem"
//     bottom="2rem"
//     zIndex={99}
//     color="dark"
//     sx={{ cursor: "pointer" }}
//     onClick={handleConfiguratorOpen}
//   >
//     <Icon fontSize="small" color="inherit">
//       settings
//     </Icon>
//   </MDBox>
// );
