/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import BarnManagement from "layouts/pages/barn-management";

// @mui icons
import Icon from "@mui/material/Icon";
import FeedInventory from "layouts/pages/feed-inventory";
import Feeding from "layouts/pages/feeding";
import Watering from "layouts/pages/watering";
import Analysis from "layouts/pages/analysis";
import GroupManagement from "layouts/pages/group-management";
import FarmInfo from "layouts/pages/farm-info";

const routes = [
  {
    type: "collapse",
    name: "기본 정보",
    key: "farm-info",
    route: "/farm-info",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <FarmInfo />,
    noCollapse: true,
  },
  { type: "title", title: "농장", key: "title-farm" },
  {
    type: "collapse",
    name: "축사 관리",
    key: "barn",
    route: "/barn",
    icon: <Icon fontSize="medium">warehouse</Icon>,
    component: <BarnManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "그룹 관리",
    key: "group",
    route: "/group",
    icon: <Icon fontSize="medium">group_work</Icon>,
    component: <GroupManagement />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "사료 관리",
    key: "feed-inventory",
    route: "/feed-inventory",
    icon: <Icon fontSize="medium">inventory</Icon>,
    component: <FeedInventory />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "사료 급이 현황",
    key: "feeding",
    route: "/feeding",
    icon: <Icon fontSize="medium">grass</Icon>,
    component: <Feeding />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "급수 현황",
    key: "watering",
    route: "/watering",
    icon: <Icon fontSize="medium">water</Icon>,
    component: <Watering />,
    noCollapse: true,
  },
  { type: "title", title: "경영", key: "title-business" },
  {
    type: "collapse",
    name: "탄소감축량 및 수익 분석",
    key: "analysis",
    route: "/analysis",
    icon: <Icon fontSize="medium">assessment</Icon>,
    component: <Analysis />,
    noCollapse: true,
  },
  { type: "divider", key: "div" },
];

export default routes;
