/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import DefaultLineChart from "components/DefaultLineChart";

// Sales dashboard components
import GroupsChart from "components/GroupsChart";

import Footer from "components/Footer";
import ProfileInfoCard from "components/ProfileInfoCard";
import useAxios from "axios-hooks";
import { MAIN } from "config";
import { Backdrop, Card, CircularProgress, SvgIcon } from "@mui/material";
import { useAtom } from "jotai";
import { currentFarmAtom, currentFarmIdAtom } from "atoms/farmAtoms";
import { barnListAtom } from "atoms/barnAtoms";
import { groupListAtom } from "atoms/groupAtoms";
import SimpleInfoCard from "components/simple-info-card";
import { CowIcon } from "components/icons";
import SimplePlusInfoCard from "components/simple-plus-info-card";
import ReactWeather, { useOpenWeather } from "react-open-weather";
import { CustomOverlayMap, Map, MapMarker, ZoomControl } from "react-kakao-maps-sdk";
import MDBadge from "components/MDBadge";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import PriceInfoCard from "components/price-info-card";
import { DateTime } from "luxon";
import { Feeding } from "models/feeding";

interface Types {
  labels: any;
  datasets: any;
}

const verticalBarChartData: Types = {
  labels: ["1-5", "6-10", "11-15", "16-19", "20-24", "24+"],
  datasets: [
    {
      label: "개체 수",
      color: "dark",
      data: [15, 20, 12, 30, 20, 15],
    },
  ],
};

interface Coordinate {
  latitude: number;
  longitude: number;
}

const chartLabels = Array(7)
  .fill("")
  .map((_, index) => {
    const date = DateTime.now().minus({ days: index });
    return date.toISODate();
  });

function FarmInfo(): JSX.Element {
  const [farmId] = useAtom(currentFarmIdAtom);
  const [currentFarm] = useAtom(currentFarmAtom);
  const [barnList] = useAtom(barnListAtom);
  const [groupList] = useAtom(groupListAtom);
  const [coordinate, setCoordinate] = useState<Coordinate>({ latitude: 0, longitude: 0 });
  const [animalCount, setAnimalCount] = useState(0);
  const [chartData, setChartData] = useState<Types>({
    labels: chartLabels,
    datasets: [
      {
        label: "급이량",
        color: "info",
        data: Array(7).fill(0),
      },
      {
        label: "급수량",
        color: "dark",
        data: Array(7).fill(0),
      },
    ],
  });

  const [{ data: customer, loading }, fetchCustomerData] = useAxios({
    url: `${MAIN}/customer/${farmId}`,
  });

  const [{ data: priceData, loading: priceLoading }, fetchPriceData] = useAxios({
    url: `${MAIN}/auction-price`,
  });

  const [{ data: feedingData, loading: feedingLoading }, fetchFeedingData] = useAxios({
    url: `${MAIN}/feedings`,
  });
  const [{ data: wateringData, loading: wateringLoading }, fetchWateringData] = useAxios({
    url: `${MAIN}/waterings`,
  });

  const startDate = DateTime.now().minus({ week: 1 }).toISODate();
  const endDate = DateTime.now().toISODate();

  useEffect(() => {
    if (farmId > 0) {
      fetchCustomerData();
      fetchPriceData();
      fetchFeedingData({
        params: {
          farmId,
          startDate,
          endDate,
          orderBy: "feedDate",
        },
      });
      fetchWateringData({
        params: {
          farmId,
          startDate,
          endDate,
          orderBy: "wateringDate",
        },
      });
    }
  }, [farmId]);

  useEffect(() => {
    if (farmId > 0) {
      const totalCount = barnList.reduce((prevValue, barn) => prevValue + barn.animalCount, 0);

      setAnimalCount(totalCount);
    }
  }, [barnList]);

  useEffect(() => {
    if (farmId > 0) {
      setCoordinate({
        latitude: parseFloat(currentFarm.latitude),
        longitude: parseFloat(currentFarm.longitude),
      });
    }
  }, [currentFarm]);

  useEffect(() => {
    if (farmId > 0 && feedingData) {
      const aggregated = feedingData.feedings.reduce((prev: number[], feeding: Feeding) => {
        const feedingsPerday = prev;
        const { feedDate, feedQuantity, barnId } = feeding;
        if (barnId) {
          const labelIndex = chartLabels.findIndex((label) => label === feedDate.split(" ")[0]);
          if (labelIndex > -1) {
            feedingsPerday[labelIndex] += feedQuantity;
          }
        }
        return feedingsPerday;
      }, Array(7).fill(0));

      setChartData((prev) => ({
        labels: chartLabels,
        datasets: [
          {
            label: "급이량",
            color: "info",
            data: aggregated,
          },
          {
            label: "급수량",
            color: "dark",
            data: prev.datasets[1].data,
          },
        ],
      }));
    }
  }, [feedingData]);

  useEffect(() => {
    if (farmId > 0 && wateringData) {
      const aggregated = wateringData.waterings.reduce((prev: number[], watering: any) => {
        const wateringsPerDay = prev;
        const { wateringDate, amount, barnId } = watering;
        if (barnId) {
          const labelIndex = chartLabels.findIndex((label) => label === wateringDate.split(" ")[0]);
          if (labelIndex > -1) {
            wateringsPerDay[labelIndex] += amount;
          }
        }
        return wateringsPerDay;
      }, Array(7).fill(0));

      setChartData((prev) => ({
        labels: chartLabels,
        datasets: [
          {
            label: "급이량",
            color: "info",
            data: prev.datasets[0].data,
          },
          {
            label: "급수량",
            color: "dark",
            data: aggregated,
          },
        ],
      }));
    }
  }, [wateringData]);

  const { data, isLoading, errorMessage } = useOpenWeather({
    key: "f620e6d14c91130cae24c16d633b9f91",
    lat: currentFarm?.latitude ?? "0",
    lon: currentFarm?.longitude ?? "0",
    lang: "ko",
    unit: "metric",
  });

  const customStyles = {
    gradientStart: "#0181C2",
    gradientMid: "#04A7F9",
    gradientEnd: "#4BC4F7",
    locationFontColor: "#FFF",
    todayTempFontColor: "#FFF",
    todayDateFontColor: "#B5DEF4",
    todayRangeFontColor: "#B5DEF4",
    todayDescFontColor: "#B5DEF4",
    todayInfoFontColor: "#B5DEF4",
    todayIconColor: "#FFF",
    forecastBackgroundColor: "#FFF",
    forecastSeparatorColor: "#DDD",
    forecastDateColor: "#777",
    forecastDescColor: "#777",
    forecastRangeColor: "#777",
    forecastIconColor: "#4BC4F7",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <Card sx={{ overflow: "hidden" }}>
                <ReactWeather
                  theme={customStyles}
                  isLoading={isLoading}
                  errorMessage={errorMessage}
                  data={data}
                  lang="ko"
                  locationLabel={currentFarm?.addressR.split(" ")[0]}
                  unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
                  showForecast
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Card sx={{ overflow: "hidden", height: "100%" }}>
                <Map
                  center={{ lat: coordinate.latitude, lng: coordinate.longitude }}
                  style={{ width: "100%", height: "100%" }}
                  scrollwheel={false}
                >
                  <CustomOverlayMap
                    position={{ lat: coordinate.latitude, lng: coordinate.longitude }}
                    yAnchor={2}
                  >
                    <MDBadge badgeContent={currentFarm?.farmName} container />
                  </CustomOverlayMap>
                  <MapMarker
                    position={{
                      lat: coordinate.latitude,
                      lng: coordinate.longitude,
                    }}
                  />
                  <ZoomControl position={window.kakao.maps.ControlPosition.TOPRIGHT} />
                </Map>
              </Card>
            </Grid>
            {currentFarm && (
              <Grid item xs={12} sm={6} lg={4}>
                <ProfileInfoCard
                  title={currentFarm.farmName}
                  description={currentFarm.memo}
                  info={{
                    농장등록번호: currentFarm.farmUniqueId,
                    축종: currentFarm.animalType,
                    전화번호: currentFarm.phoneNum,
                    농장담당자: currentFarm.contactName,
                    담당자직책: currentFarm.contactDuty,
                    주소: currentFarm.addressR,
                    상세주소: currentFarm.addressDetail,
                    등록일자: new Date(currentFarm.createdAt).toLocaleDateString("ko-KR"),
                  }}
                  action={{ route: "", tooltip: "Edit Profile" }}
                />
              </Grid>
            )}
            {customer && (
              <Grid item xs={12} sm={6} lg={4}>
                <ProfileInfoCard
                  title={customer.customerName}
                  description={customer.memo}
                  info={{
                    사업자구분: customer.customerType === "sole" ? "개인" : "법인",
                    소유주: customer.ownerName,
                    전화번호: customer.phoneNum,
                    이메일: customer.email,
                    사업자번호: customer.registrationNum,
                    등록일자: new Date(customer.createdAt).toLocaleDateString("ko-KR"),
                  }}
                  action={{ route: "", tooltip: "Edit Profile" }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} lg={4}>
              <MDBox mb={3}>
                <SimplePlusInfoCard
                  color="primary"
                  icon="warehouse"
                  title="축사 현황"
                  count={`${barnList.length} 개`}
                  latest={barnList[0]?.barnName}
                />
              </MDBox>
              <MDBox mb={3}>
                <SimplePlusInfoCard
                  color="info"
                  icon="group_work"
                  title="그룹 현황"
                  count={`${groupList.length} 개`}
                  latest={groupList[0]?.groupName}
                />
              </MDBox>
              <SimpleInfoCard
                color="success"
                icon={
                  <SvgIcon>
                    <CowIcon />
                  </SvgIcon>
                }
                title="개체 수"
                count={`${animalCount} 개`}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <GroupsChart />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <VerticalBarChart
                title="월령별 개체 현황"
                description="월령구간 별 개체 수"
                chart={verticalBarChartData}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <DefaultLineChart
                title="급이/급수 현황"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="사료 급이량" />
                      <MDBadgeDot color="dark" size="sm" badgeContent="급수량" />
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="축사 기준 급이/급수량" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart={chartData}
              />
            </Grid>
            {priceData && (
              <>
                <Grid item xs={12} sm={6} lg={3}>
                  <PriceInfoCard
                    color="secondary"
                    icon={
                      <SvgIcon>
                        <CowIcon />
                      </SvgIcon>
                    }
                    title="전국 한우 시세"
                    count={`${priceData[16].hanwooAuctAmt} 원/kg`}
                    priceGap={parseInt(priceData[16].hanwooAuctdiffAmt, 10)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <PriceInfoCard
                    color="dark"
                    icon={
                      <SvgIcon>
                        <CowIcon />
                      </SvgIcon>
                    }
                    title="전국 육우 시세"
                    count={`${priceData[16].yukwooAuctAmt} 원/kg`}
                    priceGap={parseInt(priceData[16].yukwooAuctdiffAmt, 10)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || priceLoading || feedingLoading || wateringLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
}

export default FarmInfo;
