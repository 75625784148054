/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/DataTable";

import { useAtom } from "jotai";
import { currentFarmIdAtom } from "atoms/farmAtoms";
import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { MAIN } from "config";
import {
  Backdrop,
  CircularProgress,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import ConfirmDialog from "components/confirm-dialog";
import { useFormik } from "formik";
import axios from "axios";

function GroupManagement(): JSX.Element {
  const [farmId] = useAtom(currentFarmIdAtom);
  const [open, setOpen] = useState<boolean>(false);

  const [tableData, setTableData] = useState({
    columns: [
      { Header: "그룹 타입", accessor: "groupType" },
      { Header: "그룹명", accessor: "groupName" },
      { Header: "가축수", accessor: "animalCount" },
    ],
    rows: [],
  });
  // const [rowCount, setRowCount] = useState<number>(0);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const [{ data, loading }, fetchData] = useAxios({
    url: `${MAIN}/groups`,
  });

  useEffect(() => {
    if (farmId > 0) {
      fetchData({
        params: {
          farmId,
        },
      });
    }
  }, [farmId]);

  useEffect(() => {
    if (data) {
      setTableData((prev) => ({ columns: prev.columns, rows: data.groups }));
    }
  }, [data, fetchData]);

  const onClickAdd = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSave = async (newGroup: any) => {
    try {
      setSaveLoading(true);
      const { data: group } = await axios.post(`${MAIN}/group`, { farmId, ...newGroup });

      setTableData((prev) => ({ columns: prev.columns, rows: [group, ...prev.rows] }));
      setOpen(false);
      setSaveLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues: any = {
    groupName: "",
    groupType: "",
    information: "",
    animalCount: 0,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSave,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={6}>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  그룹 관리
                </MDTypography>
                <MDTypography variant="button" color="text">
                  그룹 현황
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox display="flex" justifyContent="flex-end" m={3}>
                <MDButton onClick={onClickAdd} color="info" variant="gradient">
                  + 그룹 추가
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
          <DataTable table={tableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <ConfirmDialog
        open={open}
        title="신규 그룹 정보 입력"
        confirmText="저장"
        handleConfirm={formik.handleSubmit}
        handleCancel={handleCloseDialog}
      >
        <DialogContentText sx={{ paddingBottom: "10px" }}>
          신규로 등록할 그룹 정보를 입력해주세요.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDInput
              name="groupName"
              label="그룹 이름"
              fullWidth
              value={formik.values.groupName}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="groupType">그룹 타입</InputLabel>
              <Select
                labelId="groupType"
                name="groupType"
                label="그룹 타입"
                value={formik.values.groupType}
                onChange={formik.handleChange}
              >
                <MenuItem value="incoming">입고</MenuItem>
                <MenuItem value="growing">육성기</MenuItem>
                <MenuItem value="fattening">비육전기</MenuItem>
                <MenuItem value="post">비육후기</MenuItem>
                <MenuItem value="etc">기타</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="information"
              multiline
              rows={4}
              label="기타 정보"
              fullWidth
              value={formik.values.information}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="animalCount"
              type="number"
              label="가축수"
              fullWidth
              value={formik.values.animalCount}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || saveLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
}

export default GroupManagement;
