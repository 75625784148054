import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Footer from "components/Footer";

import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { ADMIN } from "config";
import { Backdrop, CircularProgress, DialogContentText, Grid } from "@mui/material";
import ConfirmDialog from "components/confirm-dialog";
import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import PaginationTable from "components/PaginationTable";
import AdminLayout from "components/AdminLayout";
import AdminNavbar from "components/AdminNavbar";
import { AdminCreate } from "models/admin";

function Admins(): JSX.Element {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "이메일", accessor: "email" },
      { Header: "이름", accessor: "username" },
      { Header: "역할", accessor: "role" },
      {
        Header: "계정 활성화 여부",
        accessor: "isActive",
        Cell: ({ value }: { value: number }) => (value ? "✅" : "❌"),
      },
    ],
    rows: [],
  });

  const [{ data, loading }, fetchData] = useAxios({
    url: `${ADMIN}/admins`,
  });

  useEffect(() => {
    fetchData({
      params: {
        offset: pageIndex,
        size: pageSize,
      },
    });
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (data) {
      setTableData((prev) => ({ columns: prev.columns, rows: data.admins }));
      setRowCount(data.count);
    }
  }, [data, fetchData]);

  // const onClickAdd = () => {
  //   setOpenDialog(true);
  // };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSave = async (formData: any) => {
    try {
      setSaveLoading(true);
      const { data: admin } = await axios.post(`${ADMIN}/admin`, formData);

      setTableData((prev) => ({ columns: prev.columns, rows: [admin, ...prev.rows] }));
      setOpenDialog(false);
      setSaveLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues: AdminCreate = {
    username: "",
    email: "",
    password: "",
    role: "admin",
    isActive: false,
  };

  const validationSchema = Yup.object({
    username: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    role: Yup.string().default("admin").required(),
    isActive: Yup.boolean().default(false).required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSave,
  });

  return (
    <AdminLayout>
      <AdminNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={6}>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  사내 계정
                </MDTypography>
                <MDTypography variant="button" color="text">
                  사내 계정 관리
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <PaginationTable
            table={tableData}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            rowCount={rowCount}
            canSearch
          />
        </Card>
      </MDBox>
      <Footer />
      <ConfirmDialog
        open={openDialog}
        title="신규 계정 정보 수정"
        confirmText="수정"
        handleConfirm={formik.handleSubmit}
        handleCancel={handleCloseDialog}
      >
        <DialogContentText sx={{ paddingBottom: "10px" }}>
          수정할 사내 계정 정보를 입력해주세요.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDInput
              name="username"
              label="이름"
              fullWidth
              value={formik.values.username}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="email"
              label="이메일"
              type="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="password"
              label="암호"
              type="password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="role"
              label="Role"
              fullWidth
              value={formik.values.role}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || saveLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AdminLayout>
  );
}

export default Admins;
