/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-farm-manager.jpg";
import { useFormik } from "formik";
import { MAIN } from "config";
import axios from "axios";
import Swal from "sweetalert2";
import { useMaterialUIController } from "context";
import { useTheme } from "@mui/material";

function SignUp(): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { palette } = useTheme();
  const navigate = useNavigate();

  const signUp = async (values: any) => {
    try {
      if (values.isAgreeTerm) {
        await axios.post(`${MAIN}/sign-up`, values);

        Swal.fire({
          title: "가입이 승인되면 이용하실 수 있습니다.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "확인",
          confirmButtonColor: darkMode ? palette.info.dark : palette.info.main,
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            navigate("/login");
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      isAgreeTerm: false,
    },
    onSubmit: signUp,
  });

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            서비스 가입
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            가입을 위해 이메일과 암호를 입력해주세요
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <form onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  name="username"
                  type="text"
                  label="사용자 이름"
                  onChange={formik.handleChange}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  name="email"
                  type="email"
                  label="이메일"
                  onChange={formik.handleChange}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  name="password"
                  type="password"
                  label="암호"
                  onChange={formik.handleChange}
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox name="isAgreeTerm" onChange={formik.handleChange} />
                <MDTypography
                  component="a"
                  href="https://farmmorning-terms.oopy.io/terms"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  이용약관
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  &nbsp;에 동의 합니다.&nbsp;
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" variant="gradient" color="info" fullWidth>
                  가입하기
                </MDButton>
              </MDBox>
            </form>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                이미 계정이 있으신가요?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  로그인
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default SignUp;
