import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { groupListAtom } from "atoms/groupAtoms";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "examples/Charts/PieChart";

function GroupsChart(): JSX.Element {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  const [groupList] = useAtom(groupListAtom);
  const [chartData, setChartData] = useState({
    labels: ["도입기", "육성기", "비육전기", "비육후기"],
    datasets: {
      label: "개체수",
      backgroundColors: ["info", "success", "warning", "primary"],
      data: [0, 0, 0, 0],
    },
  });
  const [maxGroup, setMaxGroup] = useState<number>(0);
  const [minGroup, setMinGroup] = useState<number>(0);

  useEffect(() => {
    const animalCounts = groupList.reduce(
      (prev, group) => {
        const current = prev;
        if (group.groupType === "calf") {
          current[0] += group.animalCount;
        } else if (group.groupType === "growing") {
          current[1] += group.animalCount;
        } else if (group.groupType === "fattening") {
          current[2] += group.animalCount;
        } else {
          current[3] += group.animalCount;
        }
        return current;
      },
      [0, 0, 0, 0]
    );
    setMaxGroup(animalCounts.indexOf(Math.max(...animalCounts)));
    setMinGroup(animalCounts.indexOf(Math.min(...animalCounts)));

    setChartData((prev) => ({ ...prev, datasets: { ...prev.datasets, data: animalCounts } }));
  }, [groupList]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6">그룹별 개체 현황</MDTypography>
        <Tooltip title="사육구분별 개체 수" placement="bottom" arrow>
          <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
            <Icon>priority_high</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
      <MDBox mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <PieChart chart={chartData} height="12.5rem" />
          </Grid>
          <Grid item xs={5}>
            <MDBox pr={1}>
              <MDBox mb={1}>
                <MDBadgeDot color="info" size="sm" badgeContent="도입기" />
              </MDBox>
              <MDBox mb={1}>
                <MDBadgeDot color="success" size="sm" badgeContent="육성기" />
              </MDBox>
              <MDBox mb={1}>
                <MDBadgeDot color="warning" size="sm" badgeContent="비육전기" />
              </MDBox>
              <MDBox mb={1}>
                <MDBadgeDot color="primary" size="sm" badgeContent="비육후기" />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox
        pt={4}
        pb={2}
        px={2}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            가장 많은 개체 수 보유{" "}
            <strong>{["도입기", "육성기", "비육전기", "비육후기"][maxGroup]}</strong>
            <br />
            가장 적은 개체 수 보유{" "}
            <strong>{["도입기", "육성기", "비육전기", "비육후기"][minGroup]}</strong>
          </MDTypography>
        </MDBox>
        {/* <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
          <MDButton color={darkMode ? "white" : "light"}>자세히 보기</MDButton>
        </MDBox> */}
      </MDBox>
    </Card>
  );
}

export default GroupsChart;
