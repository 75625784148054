/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { useAtom } from "jotai";
import { currentFarmIdAtom } from "atoms/farmAtoms";
import { useFormik } from "formik";
import { MAIN } from "config";
import axios from "axios";
import useAxios from "axios-hooks";
import { Barn, BarnCreate } from "models/barn";
import { CowIcon } from "components/icons";
import {
  Grid,
  Backdrop,
  CircularProgress,
  DialogContentText,
  SvgIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  styled,
} from "@mui/material";
import ConfirmDialog from "../../../components/confirm-dialog";

function NewBarnDialog({ open, formik, handleCancel }: any) {
  return (
    <ConfirmDialog
      open={open}
      title="신규 축사 정보 입력"
      confirmText="저장"
      handleConfirm={formik.handleSubmit}
      handleCancel={handleCancel}
    >
      <DialogContentText sx={{ paddingBottom: "10px" }}>
        신규로 등록할 축사 정보를 입력해주세요.
      </DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDInput
            name="barnName"
            label="축사 이름"
            fullWidth
            value={formik.values.barnName}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <MDInput
            name="barnType"
            label="축사 타입"
            fullWidth
            value={formik.values.barnType}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <MDInput
            name="information"
            multiline
            rows={4}
            label="기타 정보"
            fullWidth
            value={formik.values.information}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <MDInput
            name="animalCount"
            type="number"
            label="가축수"
            fullWidth
            value={formik.values.animalCount}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
}

const AnimalStatusBox = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "20px",
});

const Animal = styled("div")({
  fontSize: "3rem",
  marginRight: "10px",
});

function BarnManagement(): JSX.Element {
  const [farmId] = useAtom(currentFarmIdAtom);
  const [barns, setBarns] = useState<Barn[]>([]);

  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [selectedBarn, setSelectedBarn] = useState<Barn | null>(null);

  const onClickBarn = (barn: Barn) => {
    setSelectedBarn(barn);
    setOpenDetail(true);
  };

  const [{ data, loading }, fetchData] = useAxios({
    url: `${MAIN}/farm/${farmId}`,
  });

  useEffect(() => {
    if (farmId > 0) {
      fetchData();
    }
  }, [farmId]);

  useEffect(() => {
    if (data) {
      setBarns(data.barns);
    }
  }, [data, fetchData]);

  const [open, setOpen] = useState<boolean>(false);

  const onClickAddCard = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSave = async (newBarn: BarnCreate) => {
    try {
      const { data } = await axios.post(`${MAIN}/barn`, { farmId, ...newBarn });

      setBarns([...barns, data]);
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues: BarnCreate = {
    barnName: "",
    barnType: "",
    information: "",
    animalCount: 0,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSave,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            {barns.length > 0 &&
              barns.map((barn) => (
                <Grid key={barn.id} item xs={12} md={6} lg={3}>
                  <MDBox style={{ cursor: "pointer" }} mb={1.5} onClick={() => onClickBarn(barn)}>
                    <DefaultInfoCard
                      color="success"
                      icon={
                        <SvgIcon>
                          <CowIcon />
                        </SvgIcon>
                      }
                      title={barn.barnName}
                      description={barn.barnType}
                      value={`${barn.animalCount} 마리`}
                    />
                  </MDBox>
                </Grid>
              ))}
            <Grid item xs={12} md={6} lg={3}>
              <MDBox style={{ cursor: "pointer" }} mb={1.5} onClick={onClickAddCard}>
                <DefaultInfoCard
                  color="info"
                  icon="library_add"
                  title="축사 추가"
                  description="신규 축사 입력"
                  value="+"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      <NewBarnDialog open={open} formik={formik} handleCancel={handleCloseDialog} />
      <Dialog open={openDetail} onClose={() => setOpenDetail(false)}>
        <DialogTitle>축사 정보</DialogTitle>
        <DialogContent dividers>
          <List>
            <ListItem disablePadding>
              <ListItemText primary={`축사이름 : ${selectedBarn?.barnName}`} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary={`축사종류 : ${selectedBarn?.barnType}`} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary={`가축두수 : ${selectedBarn?.animalCount}`} />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText primary={`기타정보 : ${selectedBarn?.information}`} />
            </ListItem>
            <ListItem>
              <AnimalStatusBox>
                {Array(selectedBarn?.animalCount)
                  .fill("cow")
                  .map((_, index) => (
                    <Animal>
                      <SvgIcon key={index}>
                        <CowIcon />
                      </SvgIcon>
                    </Animal>
                  ))}
              </AnimalStatusBox>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
}

export default BarnManagement;
