/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-farm-manager.jpg";
import { useFormik } from "formik";
import axios from "axios";
import { MAIN } from "config";
import axiosConfig from "axios/axios";
import { useAtom } from "jotai";
import { loginModeAtom } from "atoms/globalAtoms";
import { useState } from "react";
import MDSnackbar from "components/MDSnackbar";
import * as Yup from "yup";

function LogIn(): JSX.Element {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [, setLoginMode] = useAtom(loginModeAtom);

  const navigate = useNavigate();

  const toggleAlert = () => {
    setShowAlert(false);
  };

  const login = async (values: any) => {
    try {
      const { data } = await axios.post(`${MAIN}/login`, values);
      const { accessToken } = data;

      localStorage.setItem("token", accessToken);
      setLoginMode("main");
      axiosConfig();

      navigate("/farm-info");
    } catch (error) {
      setAlertMessage("로그인에 실패하였습니다");
      setShowAlert(true);
      console.error(error);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("이메일을 입력해주세요"),
    password: Yup.string().required("암호를 입력해주세요"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: login,
  });

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            로그인
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <form onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  name="email"
                  type="email"
                  label="이메일"
                  fullWidth
                  onChange={formik.handleChange}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  name="password"
                  type="password"
                  label="암호"
                  fullWidth
                  onChange={formik.handleChange}
                />
              </MDBox>
              {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;아이디 기억하기
              </MDTypography>
            </MDBox> */}
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" variant="gradient" color="info" fullWidth>
                  로그인
                </MDButton>
              </MDBox>
            </form>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                계정이 아직 없습니까?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  가입하기
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="error"
        icon="error"
        title="로그인 실패"
        content={alertMessage}
        dateTime="방금"
        open={showAlert}
        close={toggleAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={toggleAlert}
      />
    </BasicLayout>
  );
}

export default LogIn;
