/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/DataTable";

import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { MAIN } from "config";
import { Backdrop, CircularProgress, DialogContentText, Grid } from "@mui/material";
import { useAtom } from "jotai";
import { currentFarmIdAtom } from "atoms/farmAtoms";
import ConfirmDialog from "components/confirm-dialog";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import axios from "axios";
import MDDatePicker from "components/MDDatePicker";
import { InventoryCreate } from "models/inventory";

function FeedInventory(): JSX.Element {
  const [farmId] = useAtom(currentFarmIdAtom);
  const [open, setOpen] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const [offset] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "사료 이름", accessor: "inventoryName" },
      { Header: "사료 회사", accessor: "manufacturer" },
      {
        Header: "입고일",
        accessor: "receivingDate",
        Cell: ({ value }: any) => value.split(" ")[0],
      },
      { Header: "입고 수량", accessor: "quantity" },
      { Header: "가격", accessor: "price" },
    ],
    rows: [],
  });
  // const [rowCount, setRowCount] = useState<number>(0);

  const [{ data, loading }, fetchData] = useAxios({
    url: `${MAIN}/inventories`,
  });

  useEffect(() => {
    if (farmId > 0) {
      fetchData({
        params: {
          offset,
          size: pageSize,
          farmId,
          inventoryType: "feed",
        },
      });
    }
  }, [farmId, offset, pageSize]);

  useEffect(() => {
    if (data) {
      setTableData((prev) => ({ columns: prev.columns, rows: data.inventories }));
    }
  }, [data, fetchData]);

  const onClickAdd = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSave = async (newFeed: any) => {
    try {
      setSaveLoading(true);
      const { data: group } = await axios.post(`${MAIN}/inventory`, { farmId, ...newFeed });

      setTableData((prev) => ({ columns: prev.columns, rows: [group, ...prev.rows] }));
      setOpen(false);
      setSaveLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues: InventoryCreate = {
    inventoryName: "",
    inventoryType: "feed",
    manufacturer: "",
    receivingDate: "",
    quantity: 0,
    price: 0,
    memo: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSave,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={6}>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  사료 재고 관리
                </MDTypography>
                <MDTypography variant="button" color="text">
                  사료 구매 내역
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox display="flex" justifyContent="flex-end" m={3}>
                <MDButton onClick={onClickAdd} color="info" variant="gradient">
                  + 구매 내역 추가
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
          <DataTable table={tableData} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <ConfirmDialog
        open={open}
        title="사료 구매 내역 입력"
        confirmText="저장"
        handleConfirm={formik.handleSubmit}
        handleCancel={handleCloseDialog}
      >
        <DialogContentText sx={{ paddingBottom: "10px" }}>
          등록할 사료 구매 내역을 입력해주세요.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDInput
              name="inventoryName"
              label="사료 이름"
              fullWidth
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="manufacturer"
              label="사료 회사"
              fullWidth
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDDatePicker
              sx={{ width: "100%" }}
              name="receivingDate"
              input={{ placeholder: "입고일", fullWidth: true }}
              onChange={(value: Date) => {
                const receivingDate = new Date(value).toISOString().split("T")[0];
                formik.setFieldValue("receivingDate", receivingDate);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="quantity"
              type="number"
              label="입고량"
              fullWidth
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="price"
              type="number"
              label="가격"
              fullWidth
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="memo"
              multiline
              rows={4}
              label="기타 정보"
              fullWidth
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || saveLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
}

export default FeedInventory;
