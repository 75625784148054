import Icon from "@mui/material/Icon";
import Admins from "layouts/admin/admins";
import Customers from "layouts/admin/customers";
import Users from "layouts/admin/users";

const routes = [
  {
    type: "collapse",
    name: "사내 계정 정보 관리",
    key: "admin-management",
    route: "/admin/admins",
    icon: <Icon fontSize="medium">assignmentind</Icon>,
    component: <Admins />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "사용자 계정 관리",
    key: "user-management",
    route: "/admin/users",
    icon: <Icon fontSize="medium">person</Icon>,
    component: <Users />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "고객 정보 관리",
    key: "customer-management",
    route: "/admin/customers",
    icon: <Icon fontSize="medium">people</Icon>,
    component: <Customers />,
    noCollapse: true,
  },
  { type: "divider", key: "div" },
];

export default routes;
