import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDButton from "components/MDButton";
import { ReactNode } from "react";

export interface ConfirmDialogProps {
  children?: ReactNode;
  title: string;
  open: boolean;
  confirmText?: string;
  // eslint-disable-next-line no-unused-vars
  handleConfirm?: (data: any) => void;
  handleCancel: () => void;
}

export default function ConfirmDialog({
  children,
  title,
  open,
  confirmText = "확인",
  handleConfirm,
  handleCancel,
}: ConfirmDialogProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <MDButton color="info" variant="gradient" onClick={handleConfirm}>
          {confirmText}
        </MDButton>
        <MDButton color="secondary" variant="outlined" onClick={handleCancel}>
          취소
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
