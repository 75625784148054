/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import GoogleIcon from "@mui/icons-material/Google";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-farm-manager.jpg";
import { useFormik } from "formik";
import axios from "axios";
import { ADMIN } from "config";
import axiosConfig from "axios/axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useAtom } from "jotai";
import { loginModeAtom } from "atoms/globalAtoms";

function LogInAdmin(): JSX.Element {
  // const [rememberMe, setRememberMe] = useState<boolean>(false);
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [, setLoginMode] = useAtom(loginModeAtom);

  const navigate = useNavigate();

  const login = async (values: any) => {
    try {
      const { data } = await axios.post(`${ADMIN}/login`, values);
      const { accessToken } = data;

      localStorage.setItem("admin-token", accessToken);
      setLoginMode("admin");
      axiosConfig();
      navigate(`${ADMIN}/users`);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: login,
  });

  const onSuccess = async (response: any) => {
    const { code } = response;

    const { data } = await axios.post(`${ADMIN}/google-login`, { code });
    const { accessToken } = data;

    localStorage.setItem("admin-token", accessToken);
    setLoginMode("admin");

    axiosConfig();
    navigate(`${ADMIN}/users`);
  };

  const onError = (response: any) => {
    console.error(response);
  };

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess,
    onError,
    scope: "email profile https://www.googleapis.com/auth/plus.login",
  });

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            관리자 로그인
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <MDBox mb={2}>
              <MDButton onClick={googleLogin} variant="gradient" color="secondary" fullWidth>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                  color="inherit"
                >
                  <MDBox color="inherit" display="flex" justifyContent="flex-start" flex={1}>
                    <GoogleIcon color="inherit" />
                  </MDBox>
                  <MDBox color="inherit" display="flex" justifyContent="center" flex={2}>
                    &nbsp;Google 로그인
                  </MDBox>
                  <MDBox flex={1} />
                </MDBox>
              </MDButton>
            </MDBox>
            <form onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  name="email"
                  type="email"
                  label="이메일"
                  fullWidth
                  onChange={formik.handleChange}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  name="password"
                  type="password"
                  label="암호"
                  fullWidth
                  onChange={formik.handleChange}
                />
              </MDBox>
              {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;아이디 기억하기
              </MDTypography>
            </MDBox> */}
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" variant="gradient" color="info" fullWidth>
                  로그인
                </MDButton>
              </MDBox>
            </form>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                계정이 아직 없습니까?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  가입하기
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default LogInAdmin;
