import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import AdminNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";

import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { ADMIN } from "config";
import { Backdrop, CircularProgress, DialogContentText, Grid } from "@mui/material";
import ConfirmDialog from "components/confirm-dialog";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import PaginationTable from "components/PaginationTable";
import AdminLayout from "components/AdminLayout";
import { CustomerCreate } from "models/customer";

function Customers(): JSX.Element {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "고객/기업명", accessor: "customerName" },
      { Header: "고객/기업 구분", accessor: "customerType" },
      { Header: "소유자명", accessor: "ownerName" },
      { Header: "이베일", accessor: "email" },
      { Header: "전화번호", accessor: "phoneNum" },
      { Header: "사업자등록번호", accessor: "registrationNum" },
    ],
    rows: [],
  });

  const [{ data, loading }, fetchData] = useAxios({
    url: `${ADMIN}/customers`,
  });

  useEffect(() => {
    fetchData({
      params: {
        offset: pageIndex,
        size: pageSize,
      },
    });
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (data) {
      setTableData((prev) => ({ columns: prev.columns, rows: data.customers }));
      setRowCount(data.count);
    }
  }, [data, fetchData]);

  const onClickAdd = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSave = async (formData: any) => {
    try {
      setSaveLoading(true);
      const { data: customer } = await axios.post(`${ADMIN}/customer`, formData);

      setTableData((prev) => ({ columns: prev.columns, rows: [customer, ...prev.rows] }));
      setOpenDialog(false);
      setSaveLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues: CustomerCreate = {
    customerName: "",
    customerType: "",
    ownerName: "",
    email: "",
    phoneNum: "",
    registrationNum: "",
    memo: "",
  };

  const validationSchema = Yup.object({
    customerName: Yup.string().required(),
    customerType: Yup.string().required(),
    ownerName: Yup.string().required(),
    email: Yup.string().email().required(),
    phoneNum: Yup.string().required(),
    registrationNum: Yup.string(),
    memo: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSave,
  });

  return (
    <AdminLayout>
      <AdminNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={6}>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  고객정보
                </MDTypography>
                <MDTypography variant="button" color="text">
                  고객정보 관리
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox display="flex" justifyContent="flex-end" m={3}>
                <MDButton onClick={onClickAdd} color="info" variant="gradient">
                  고객정보 생성
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
          <PaginationTable
            table={tableData}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            rowCount={rowCount}
            canSearch
          />
        </Card>
      </MDBox>
      <Footer />
      <ConfirmDialog
        open={openDialog}
        title="신규 고객 정보 입력"
        confirmText="생성"
        handleConfirm={formik.handleSubmit}
        handleCancel={handleCloseDialog}
      >
        <DialogContentText sx={{ paddingBottom: "10px" }}>
          신규로 등록할 계정 정보를 입력해주세요.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDInput
              name="customerName"
              label="고객/기업명"
              fullWidth
              value={formik.values.customerName}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="customerType"
              label="개인/기업 구분"
              fullWidth
              value={formik.values.customerName}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="ownerName"
              label="소유자명"
              fullWidth
              value={formik.values.customerName}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="email"
              label="이메일"
              type="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="phoneNum"
              label="전화번호"
              fullWidth
              value={formik.values.phoneNum}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="registrationNum"
              label="사업자등록번호"
              fullWidth
              value={formik.values.registrationNum}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="memo"
              multiline
              rows={4}
              label="기타 정보"
              fullWidth
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || saveLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AdminLayout>
  );
}

export default Customers;
