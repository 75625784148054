import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "components/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { MAIN } from "config";
import { currentFarmAtom, currentFarmIdAtom, farmListAtom } from "atoms/farmAtoms";
import { useAtom } from "jotai";
import { barnListAtom } from "atoms/barnAtoms";
import { groupListAtom } from "atoms/groupAtoms";
import { feedListAtom } from "atoms/inventoryAtoms";
import { Farm } from "models/farm";

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar({ absolute, light, isMini }: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState<any>(false);
  const route = useLocation().pathname.split("/").slice(1);

  const [farmId, setFarmId] = useAtom(currentFarmIdAtom);
  const [farmList, setFarmList] = useAtom(farmListAtom);
  const [, setCurrentFarm] = useAtom(currentFarmAtom);
  const [, setBarnList] = useAtom(barnListAtom);
  const [, setGroupList] = useAtom(groupListAtom);
  const [, setFeedList] = useAtom(feedListAtom);

  const getFarms = async () => {
    try {
      const { data } = await axios.get(`${MAIN}/farms`);

      const { farms } = data;
      setFarmList(farms);

      const storedFarmId = localStorage.getItem("farmId");
      if (!storedFarmId) {
        setFarmId(farms[0].id);
        setCurrentFarm(farms[0]);
        localStorage.setItem("farmId", farms[0].id);
      } else {
        setFarmId(parseInt(storedFarmId, 10));
        setCurrentFarm(farms.find((farm: Farm) => farm.id === parseInt(storedFarmId, 10)));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFarms();
  }, []);

  const getBarns = async () => {
    try {
      const { data } = await axios.get(`${MAIN}/barns`, {
        params: {
          farmId,
        },
      });

      setBarnList(data.barns);
    } catch (error) {
      console.error(error);
    }
  };

  const getGroups = async () => {
    try {
      const { data } = await axios.get(`${MAIN}/groups`, {
        params: {
          farmId,
        },
      });

      setGroupList(data.groups);
    } catch (error) {
      console.error(error);
    }
  };

  const getFeedList = async () => {
    try {
      const { data } = await axios.get(`${MAIN}/inventories`, {
        params: {
          farmId,
          inventoryType: "feed",
        },
      });

      setFeedList(data.inventories);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (farmId > 0) {
      getBarns();
      getGroups();
      getFeedList();
    }
  }, [farmId]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const onChangeFarm = (event: any) => {
    setFarmId(event.target.value);
    localStorage.setItem("farmId", event.target.value);
    setCurrentFarm(farmList.find((farm: Farm) => farm.id === event.target.value));
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={navbarContainer}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1} sx={{ width: "15rem" }}>
              <FormControl fullWidth>
                <InputLabel id="farms">농장 선택</InputLabel>
                <Select labelId="farms" label="농장 선택" value={farmId} onChange={onChangeFarm}>
                  {farmList.map((farm) => (
                    <MenuItem key={farm.id} value={farm.id}>
                      {farm.farmName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MDBox>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
