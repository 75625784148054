/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";

import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { MAIN } from "config";
import {
  Backdrop,
  CircularProgress,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useAtom } from "jotai";
import { currentFarmIdAtom } from "atoms/farmAtoms";
import ConfirmDialog from "components/confirm-dialog";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import axios from "axios";
import MDDatePicker from "components/MDDatePicker";
import { barnListAtom } from "atoms/barnAtoms";
import { groupListAtom } from "atoms/groupAtoms";
import * as Yup from "yup";
import PaginationTable from "components/PaginationTable";

function Watering(): JSX.Element {
  const [farmId] = useAtom(currentFarmIdAtom);
  const [barnList] = useAtom(barnListAtom);
  const [groupList] = useAtom(groupListAtom);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [tableData, setTableData] = useState({
    columns: [
      {
        Header: "급수일자",
        accessor: "wateringDate",
        Cell: ({ value }: any) => value.split(" ")[0],
      },
      {
        Header: "축사",
        accessor: "barn.barnName",
      },
      { Header: "그룹", accessor: "group.groupName" },
      { Header: "급수량", accessor: "amount" },
    ],
    rows: [],
  });

  const [{ data, loading }, fetchData] = useAxios({
    url: `${MAIN}/waterings`,
  });

  useEffect(() => {
    if (farmId > 0) {
      fetchData({
        params: {
          offset: pageIndex,
          size: pageSize,
          farmId,
        },
      });
    }
  }, [farmId, pageIndex, pageSize]);

  useEffect(() => {
    if (data) {
      setTableData((prev) => ({ columns: prev.columns, rows: data.waterings }));
      setRowCount(data.count);
    }
  }, [data, fetchData]);

  const onClickAdd = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSave = async (formData: any) => {
    try {
      setSaveLoading(true);
      const newWatering = formData;
      if (newWatering.barnId === 0) {
        delete newWatering.barnId;
      }
      if (newWatering.groupId === 0) {
        delete newWatering.groupId;
      }
      const { data: watering } = await axios.post(`${MAIN}/watering`, {
        farmId,
        ...newWatering,
      });

      setTableData((prev) => ({ columns: prev.columns, rows: [watering, ...prev.rows] }));
      setOpenDialog(false);
      setSaveLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues: any = {
    barnId: 0,
    groupId: 0,
    wateringDate: "",
    amount: 0,
  };

  const validationSchema = Yup.object({
    barnId: Yup.number().when("groupId", {
      is: 0,
      then: Yup.number().min(1).required("축사를 선택해주세요"),
    }),
    groupId: Yup.number(),
    wateringDate: Yup.date().required("급수일자를 선택해주세요"),
    amount: Yup.number().min(1, "최소 1L 이상의 급수를 입력해주세요."),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSave,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <Grid container>
            <Grid item xs={6}>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  급수 현황
                </MDTypography>
                <MDTypography variant="button" color="text">
                  급수 내역
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox display="flex" justifyContent="flex-end" m={3}>
                <MDButton onClick={onClickAdd} color="info" variant="gradient">
                  + 급수 내역 추가
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
          <PaginationTable
            table={tableData}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            rowCount={rowCount}
            canSearch
          />
        </Card>
      </MDBox>
      <Footer />
      <ConfirmDialog
        open={openDialog}
        title="급수 내역 입력"
        confirmText="저장"
        handleConfirm={formik.handleSubmit}
        handleCancel={handleCloseDialog}
      >
        <DialogContentText sx={{ paddingBottom: "10px" }}>
          등록할 급수 내역을 입력해주세요.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="barnId">축사 선택</InputLabel>
              <Select
                labelId="barnId"
                name="barnId"
                label="축사 선택"
                value={formik.values.barnId}
                onChange={formik.handleChange}
              >
                <MenuItem value={0}>미선택</MenuItem>
                {barnList.map((barn) => (
                  <MenuItem key={barn.id} value={barn.id}>
                    {barn.barnName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="groupId">그룹 선택</InputLabel>
              <Select
                labelId="groupId"
                name="groupId"
                label="그룹 선택"
                value={formik.values.groupId}
                onChange={formik.handleChange}
              >
                <MenuItem value={0}>미선택</MenuItem>
                {groupList.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.groupName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <MDDatePicker
              sx={{ width: "100%" }}
              name="wateringDate"
              input={{ placeholder: "급수일", fullWidth: true }}
              onChange={(value: Date) => {
                const feedDate = new Date(value).toISOString().split("T")[0];
                formik.setFieldValue("wateringDate", feedDate);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              name="amount"
              type="number"
              label="급수량"
              fullWidth
              value={formik.values.amount}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </ConfirmDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || saveLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
}

export default Watering;
